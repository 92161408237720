<template>
  <div v-if="isAuthorized">
    <p style="font-weight:bold; color:#635F5D">Car Value Analytics</p>
    <div style="margin-top:30px;">
      <CarValueByTime
        title="Amount of Car Sold Over Time"
        id="amount"
        y-axis-legend="Amount of Car Sold"
        mode="amount"
        line-color="#377eb8"
        point-color="#0000ff9c"
      ></CarValueByTime>
    </div>
    <div style="margin-top:50px;">
      <CarValueByTime
        title="Count of Car Sold Over Time"
        id="count"
        y-axis-legend="Car Count"
        mode="count"
        line-color="#f74e4e"
        point-color="#ff0000b5"
      ></CarValueByTime>
    </div>
  </div>
</template>
<script>
import getAxios from "../../../services/axios-get";
import CarValueByTime from "../../components/AnalyticsCharts/CarValueByTime/CarValueByTime";
export default {
  components: { CarValueByTime },
  data() {
    return {
      isAuthorized: false,
      startDate: "2018-5-01",
      endDate: "2021-6-01",
    };
  },
  created() {
    if (!localStorage.getItem("jwt")) {
      this.$router.push({ path: "/login" });
    } else {
      this.isAuthorized = true;
    }
  },
  methods: {
    getCarValueByTime() {
      let url =
        process.env.VUE_APP_ANALYTICS_URL + "/api/v1/analytics/carValueByTime";
      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      getAxios(url, params)
        .then(function() {})
        .catch(function(err) {
          console.log(err);
          this.showSnackBar("Error Occurred While Loading Chart!!");
        });
    },
  },
  beforeMount() {
    let today = new Date();
    this.endDate = today.toISOString().substr(0, 10);
  },
  mounted() {
    this.getCarValueByTime();
  },
};
</script>
>
