<template>
  <div :id="computedChartContainerId" class="CarValueByTimeChartContainer">
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div style="display:flex; justify-content:center">
      <div style="width:fit-content">
        <table border="1px">
          <tr>
            <td style="padding:5px; font-weight:600">Total Car Count</td>
            <td style="padding:5px">{{ this.totalCarCount }}</td>
          </tr>
          <tr>
            <td style="padding:5px; font-weight:600">Total Car Amount</td>
            <td style="padding:5px">{{ `$${this.totalCarAmount}` }}</td>
          </tr>
        </table>
      </div>
    </div>
    <br />
    <p style="font-weight:bold; color:#635F5D">{{ title }}</p>
    <div class="calenderContainer">
      <CalendarDialog
        v-on:callback="startDateCallback"
        :default-date="startDate"
        field-label="Starting Date"
        :controller="startDateController"
      ></CalendarDialog>
      <span>-</span>
      <CalendarDialog
        v-on:callback="endDateCallback"
        :default-date="endDate"
        field-label="Ending Date"
        :controller="endDateController"
      ></CalendarDialog>
    </div>
    <svg
      height="500"
      width="1000"
      :id="computedChartId"
      class="CarValueByTimeChart"
    ></svg>
  </div>
</template>
<script>
import * as d3 from "d3v4";
import CalendarDialog from "../../CalendarDialog/CalendarDialog.vue";
import getAxios from "../../../../services/axios-get";
export default {
  name: "CarValueByTime",
  components: { CalendarDialog },
  props: {
    title: String,
    id: String,
    yAxisLegend: String,
    mode: String,
    lineColor: String,
    pointColor: String,
  },
  computed: {
    computedChartId: function() {
      return "CarValueByTimeChart" + this.chartId;
    },
    computedChartContainerId: function() {
      return "CarValueByTimeChartContainer" + this.chartId;
    },
  },
  data() {
    return {
      chartId: "",
      snackbar: false,
      snackMessage: "",
      carValueByTimeData: null,
      width: null,
      height: 350,
      margin: {
        top: 50,
        left: 100,
        right: 100,
        bottom: 100,
      },
      carValueByTimeChartContainer: null,
      xAxisGroup: null,
      yAxisGroup: null,
      xScale: null,
      yScale: null,
      startDate: "2019-9-01",
      endDate: "2021-6-01",
      startDateController: {},
      endDateController: {},
      totalCarCount: 0,
      totalCarAmount: 0,
    };
  },
  methods: {
    showSnackBar(message) {
      this.snackMessage = message;
      this.snackbar = true;
    },

    startDateCallback(startDate) {
      if (new Date(startDate) > new Date(this.endDate)) {
        this.showSnackBar("Start Date should be less than End Date!!");
        this.startDateController.updateDateInCalendar(this.startDate);
        return;
      }
      this.startDate = startDate;
      this.getCarValueByTime("update");
    },

    endDateCallback(endDate) {
      if (new Date(endDate) < new Date(this.startDate)) {
        this.showSnackBar("End Date should be greater than Start Date!!");
        this.endDateController.updateDateInCalendar(this.endDate);
        return;
      }
      this.endDate = endDate;
      this.getCarValueByTime("update");
    },

    initGraph() {
      let svg = d3.select("#" + this.computedChartId);
      this.carValueByTimeChartContainer = svg
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left}, ${this.margin.top})`
        );
      this.xAxisGroup = this.carValueByTimeChartContainer.append("g");
      this.yAxisGroup = this.carValueByTimeChartContainer.append("g");
      let chart = document.getElementById(this.computedChartContainerId);
      this.width = chart.clientWidth - 50;
      d3.select("#" + this.computedChartId).attr("width", this.width);
      this.renderChart();
    },

    loadLegend() {
      d3.select(`.x-axis-legend-carValueByTime-${this.id}`).remove();
      d3.select(`.y-axis-legend-carValueByTime-${this.id}`).remove();

      this.carValueByTimeChartContainer
        .append("text")
        .attr("class", `x-axis-legend x-axis-legend-carValueByTime-${this.id}`)
        .attr(
          "transform",
          `translate( ${(this.width - 120) / 2}, ${
            this.width < 800 ? this.height + 100 : this.height + 50
          })`
        )
        .text("Date");

      this.carValueByTimeChartContainer
        .append("text")
        .attr("class", `y-axis-legend y-axis-legend-carValueByTime-${this.id}`)
        .attr("transform", `rotate(-90)`)
        .attr("x", -this.height / 2 - 50)
        .attr("y", -80)
        .text(this.yAxisLegend);
    },

    loadXAxis() {
      this.xScale = d3
        .scaleTime()
        .range([0, this.width - 120])
        .domain(
          d3.extent(this.carValueByTimeData, d => {
            return new Date(d["date"]);
          })
        )
        .nice();
      this.xAxisGroup
        .attr("class", `x-axis-car-value-line-chart-${this.id}`)
        .attr("transform", `translate(0,${this.yScale(0)})`)
        .call(
          d3
            .axisBottom(this.xScale)
            .tickSize(-this.height)
            .tickPadding([10])
        );
    },

    loadYAxis() {
      let amountRange = d3.extent(this.carValueByTimeData, d => {
        if (this.mode == "amount") return d["amount"];
        if (this.mode == "count") return d["carCount"];
      });
      this.yScale = d3
        .scaleLinear()
        .range([this.height, 0])
        .domain(amountRange)
        .nice();
      this.yAxisGroup
        .attr("class", `y-axis-car-value-line-chart-${this.id}`)
        .call(d3.axisLeft(this.yScale).tickSize(-this.width + 120));
    },

    loadLineChart() {
      let self = this;
      let paths = this.carValueByTimeChartContainer
        .selectAll(`.carValueByTimeline-${this.id}`)
        .data([this.carValueByTimeData], d => {
          return d["date"];
        });

      var pathMeta = paths
        .enter()
        .append("path")
        .attr("class", `carValueByTimeline-${this.id}`)
        .merge(paths)
        .attr(
          "d",
          d3
            .line()
            .x(d => {
              return self.xScale(new Date(d["date"]));
            })
            .y(d => {
              if (this.mode == "amount") return self.yScale(d["amount"]);
              if (this.mode == "count") return self.yScale(d["carCount"]);
            })
        )
        .attr("fill", "none")
        .attr("stroke", this.lineColor)
        .attr("stroke-width", 2);

      var transitionPath = d3
        .transition()
        .ease(d3.easeSin)
        .duration(2500);

      const pathLength = pathMeta.node().getTotalLength();

      pathMeta
        .attr("stroke-dashoffset", pathLength)
        .attr("stroke-dasharray", pathLength)
        .transition(transitionPath)
        .attr("stroke-dashoffset", 0);
    },

    removeCirclesBeforeUpdate() {
      d3.selectAll(`.carValueByTimeCircles-${this.id}`).remove();
    },

    loadCircles() {
      let self = this;
      let circles = this.carValueByTimeChartContainer
        .selectAll(`.carValueByTimeCircles-${this.id}`)
        .data(this.carValueByTimeData, d => {
          return d["date"];
        });

      circles
        .enter()
        .append("circle")
        .attr("class", `carValueByTimeCircles-${this.id}`)
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseLeave)
        .merge(circles)
        .attr("cx", d => this.xScale(new Date(d["date"])))
        .attr("cy", d => {
          if (this.mode == "amount") return self.yScale(d["amount"]);
          if (this.mode == "count") return self.yScale(d["carCount"]);
        })
        .transition()
        .duration(1000)
        .attr("r", 3.5)
        .attr("fill", this.pointColor);

      function onMouseOver(d) {
        let xAxis = parseInt(d3.select(this).attr("cx")) - 70;
        let yAxis = parseInt(d3.select(this).attr("cy")) - 60;
        if (xAxis + 150 > self.width - 120) {
          yAxis = yAxis + 35;
          xAxis = xAxis - 90;
        }
        self.carValueByTimeChartContainer
          .append("foreignObject")
          .attr(
            "class",
            `carValueByTimeTooltipContainer-${self.id} carValueByTimeTooltipContainer`
          )
          .attr("x", xAxis)
          .attr("y", yAxis)
          .attr("width", 150)
          .attr("height", 50)
          .html(function() {
            if (self.mode == "amount")
              return `
                                <div class="carValueByTimeTooltip">
                                    <span style="font-size:13px;color:grey">Date : ${d["date"]} </span>
                                    <span style="font-size:13px;color:grey">Amount : ${d["amount"]} </span>
                                </div>
                            `;
            if (self.mode == "count")
              return `
                                <div class="carValueByTimeTooltip">
                                    <span style="font-size:13px;color:grey">Date : ${d["date"]} </span>
                                    <span style="font-size:13px;color:grey">Car Count : ${d["carCount"]} </span>
                                </div>
                            `;
          });
      }

      function onMouseLeave() {
        d3.select(`.carValueByTimeTooltipContainer-${self.id}`).remove();
      }
    },

    renderChart() {
      this.loadYAxis();
      this.loadXAxis();
      this.loadLegend();
      if (this.width < 800) {
        d3.selectAll(`.x-axis-car-value-line-chart-${this.id} .tick text`).attr(
          "transform",
          "translate(-10, 50)rotate(-90)"
        );
      }
      this.removeCirclesBeforeUpdate();
      this.loadLineChart();
      setTimeout(() => {
        this.loadCircles();
      }, 2000);
    },

    getCarValueByTime(mode) {
      let self = this;
      let url =
        process.env.VUE_APP_ANALYTICS_URL + "/api/v1/analytics/carValueByTime";
      let params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      getAxios(url, params)
        .then(function(res) {
          self.carValueByTimeData = res["data"]["data"];
          self.totalCarAmount = d3.sum(
            self.carValueByTimeData,
            d => d["amount"]
          );
          self.totalCarCount = d3.sum(
            self.carValueByTimeData,
            d => d["carCount"]
          );
          if (mode == "init") {
            self.initGraph();
          } else {
            self.renderChart();
          }
        })
        .catch(function(err) {
          console.log(err);
          this.showSnackBar("Error Occurred While Loading Chart!!");
        });
    },

    updateSVG() {
      let chart = document.getElementById(this.computedChartContainerId);
      let currentWidth = chart.clientWidth - 50;
      this.width = currentWidth;
      d3.select("#" + this.computedChartId).attr("width", currentWidth);
      this.renderChart();
      if (currentWidth < 800) {
        d3.selectAll(`.x-axis-car-value-line-chart-${this.id} .tick text`).attr(
          "transform",
          "translate(-10, 50)rotate(-90)"
        );
      } else {
        d3.selectAll(`.x-axis-car-value-line-chart-${this.id} .tick text`).attr(
          "transform",
          "translate(0, 0)rotate(0)"
        );
      }
    },

    resizeChart() {
      window.addEventListener("resize", this.updateSVG);
    },
  },
  beforeMount() {
    let today = new Date();
    let before5Months = new Date();
    before5Months.setMonth(before5Months.getMonth() - 6);
    this.endDate = today.toISOString().substr(0, 10);
    this.startDate = before5Months.toISOString().substr(0, 10);
    this.chartId = this.id;
  },
  mounted() {
    this.getCarValueByTime("init");
    this.resizeChart();
  },
  destroyed() {
    window.removeEventListener("resize", this.updateSVG);
  },
};
</script>
<style lang="scss">
@import "./CarValueByTime.scss";
</style>
